<template>
  <div data-app="adverts-config">
    <!-- <div style="padding: 20px">
      Please be advised configuration is currently under maintenance while we resolve some issues for you.
    </div> -->
    <div class="alert alert-light alert-elevate" role="alert" v-if="suggestion !== null">
      <div class="alert-icon alert-icon-middle">
        <i class="flaticon-info kt-font-brand"></i>
      </div>
      <div class="alert-text">
        <p>
        <h5>How do I improve my ADs?</h5>
        <p>{{ suggestion.description }}</p>
        </p>
      </div>
    </div>
    <div v-show="true" class="row"
      oldstyle="display: flex; flex-flow: column wrap; justify-content: flex-start; align-items: flex-start;">
      <div class="col-md-12 col-lg-6">
        <KTPortlet title="Company Settings">
          <template v-slot:toolbar>
            <div v-if="editables.company.data === null"></div>
            <div v-else-if="editables.company.edit">
              <b-button v-if="editables.company.valid" @click="saveEdit('company')" pill variant="outline-success"
                style="margin-right: 20px;">SAVE
              </b-button>
              <b-button @click="cancelEdit('company')" pill variant="danger">CANCEL
              </b-button>
            </div>
            <b-button v-else @click="startEdit('company')" pill variant="info text-light">EDIT
            </b-button>
          </template>
          <template slot="body">
            <v-form role="form" ref="form-company" v-model="editables.company.valid"
              @submit="e => saveEdit('company', e)" lazy-validation>
              <div class="row">
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Name</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <div v-else>{{ editables.company.data.name }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.name" name="Name" autocapitalize="off"
                    autocorrect="off" spellcheck="false" type="text" label="Name" :rules="$vrules.basicText('Name')"
                    :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Language</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text" v-model="editables.company.data.language" /> -->
                    <div v-else>{{ editables.company.data.google_ads.settings.language }}</div>
                  </div>
                  <v-text-field v-else :disabled="true" :readonly="true"
                    v-model="editables.company.data.google_ads.settings.language" name="Language" autocapitalize="off"
                    autocorrect="off" spellcheck="false" type="text" label="Language"
                    :rules="$vrules.basicText('Language')" :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Website</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <div v-else>{{ editables.company.data.url }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.url" name="Website" autocapitalize="off"
                    autocorrect="off" spellcheck="false" type="text" label="Website"
                    :rules="$vrules.basicUri('Website')" :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Phone Number</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.call_extension.number" /> -->
                    <div v-else>{{ editables.company.data.google_ads.settings.call_extension.number }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.google_ads.settings.call_extension.number"
                    name="Phone Number" autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                    label="Phone Number" :rules="$vrules.basicIntlTel('Phone Number')" :required="true"></v-text-field>
                  <div class="form-group">
                    <label>Targeting accuracy
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="color: var(--info); text-align: left; cursor: pointer;" v-bind="attrs"
                            v-on="on">
                            mdi-help-circle</v-icon>
                        </template>
                        <div>
                          <div style="margin-bottom: 10px;">Select how precise you want the advertising to be.</div>
                          <div>
                            <b>HIGH</b>: provides less overall exposure but higher quality clicks.
                          </div>
                          <div><b>MEDIUM</b>: provides a good balance between exposure and quality.</div>
                          <div><b>LOW</b>: provides more overall exposure but lower quality clicks.</div>
                          <div style="margin-top: 10px;"><b>RECOMMENDED</b>: Medium</div>
                        </div>
                      </v-tooltip>
                    </label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <b-select v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.google_ads.settings.targeting_accuracy"
                      :options="['high', 'medium', 'low']" />
                    <div v-else>{{ editables.company.data.google_ads.settings.targeting_accuracy }}</div>
                  </div>
                  <div class="form-group">
                    <label>Geo-targeting accuracy
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="color: var(--info); text-align: left; cursor: pointer;" v-bind="attrs"
                            v-on="on">
                            mdi-help-circle</v-icon>
                        </template>
                        <div>
                          <div style="margin-bottom: 10px;">Select how you want to target your audience.</div>
                          <div>
                            <b>PRESENCE</b>: show ads only to people within a targeted area.
                          </div>
                          <div><b>PRESENCE OR INTEREST</b>: shows ads to people within a targeted area who also have an
                            interest.</div>
                          <div style="margin-top: 10px;"><b>RECOMMENDED</b>: Presence or interest</div>
                        </div>
                      </v-tooltip>
                    </label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <b-select v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.google_ads.settings.geotargeting_accuracy"
                      :options="[{ text: 'Presence', value: 'presence' }, { text: 'Presence or interest', value: 'presence_or_interest' }]" />
                    <div v-else>{{ editables.company.data.google_ads.settings.geotargeting_accuracy }}</div>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Alternative Name
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="color: var(--info); text-align: left; cursor: pointer;" v-bind="attrs"
                            v-on="on">
                            mdi-help-circle</v-icon>
                        </template>
                        <div>
                          Defines the name that will appear in search results if different to your company name
                        </div>
                      </v-tooltip>
                    </label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.display_name" /> -->
                    <div v-else>{{ editables.company.data.google_ads.settings.display_name }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.google_ads.settings.display_name"
                    name="Alternative Name" autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                    label="Alternative Name" :required="false"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Street Address</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.address.address" /> -->
                    <div v-else>{{ editables.company.data.address.address }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.address.address" name="Street Address"
                    autocapitalize="off" autocorrect="off" spellcheck="false" type="text" label="Street Address"
                    :rules="$vrules.basicText('Street Address')" :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>City</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.address.city" /> -->
                    <div v-else>{{ editables.company.data.address.city }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.address.city" name="City" autocapitalize="off"
                    autocorrect="off" spellcheck="false" type="text" label="City" :rules="$vrules.basicText('City')"
                    :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>State/Region/Province</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.address.state" /> -->
                    <div v-else>{{ editables.company.data.address.state }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.address.state" name="State/Region/Province"
                    autocapitalize="off" autocorrect="off" spellcheck="false" type="text" label="State/Region/Province"
                    :rules="$vrules.basicText('Region')" :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Country</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.address.country" /> -->
                    <div v-else>{{ editables.company.data.address.country }}</div>
                  </div>
                  <v-text-field v-else :disabled="true" :readonly="true"
                    v-model="editables.company.data.address.country" name="Country" autocapitalize="off"
                    autocorrect="off" spellcheck="false" type="text" label="Country"
                    :rules="$vrules.basicText('Country')" :required="true"></v-text-field>
                  <div class="form-group" v-if="!editables.company.edit">
                    <label>Postal Code</label>
                    <div v-if="editables.company.data === null">
                      <Loader :tableCount="3" :inContent="true" :noText="true" :newStyle="1">
                      </Loader>
                    </div>
                    <!-- <b-input v-else-if="editables.company.edit" type="text"
                      v-model="editables.company.data.address.postcode" /> -->
                    <div v-else>{{ editables.company.data.address.postcode }}</div>
                  </div>
                  <v-text-field v-else v-model="editables.company.data.address.postcode" name="Postal Code"
                    autocapitalize="off" autocorrect="off" spellcheck="false" type="text" label="Postal Code"
                    :rules="$vrules.basicText('Postal Code')" :required="true"></v-text-field>
                </div>
              </div>
            </v-form>
          </template>
        </KTPortlet>
      </div>
      <div class="col-md-12 col-lg-6">
        <KTPortlet title="Site links">
          <template v-slot:toolbar>
            <div v-if="editables.sitelinks.data === null"></div>
            <div v-else-if="editables.sitelinks.edit">
              <b-button
                v-if="editables.sitelinks.valid && editables.sitelinks.data.google_ads.settings.site_links.length > 0"
                @click="saveEdit('sitelinks')" pill variant="outline-success" style="margin-right: 20px;">SAVE
              </b-button>
              <b-button @click="cancelEdit('sitelinks')" pill variant="danger">CANCEL
              </b-button>
            </div>
            <b-button v-else @click="startEdit('sitelinks')" pill variant="info text-light">EDIT
            </b-button>
          </template>
          <template slot="body">
            <v-form role="form" ref="form-sitelinks" v-model="editables.sitelinks.valid"
              @submit="e => saveEdit('sitelinks', e)" lazy-validation>
              <div class="row">
                <Loader :tableCount="3" v-if="editables.sitelinks.data === null" :inContent="true" :noText="true"
                  :newStyle="2" :fields="['Title', 'URL']">
                </Loader>
                <div v-else-if="editables.sitelinks.data.google_ads.settings.site_links.length === 0"
                  style="margin: 0 auto;">
                  <template v-if="editables.sitelinks.edit">
                    <h5 class="text-danger">You need a minimum of 1 site link</h5>
                    <v-text-field v-if="editables.sitelinks.edit" style="display: none;"
                      :rules="$vrules.alwaysErrorMessage('You need at least one site link')"></v-text-field>
                  </template>
                  <span v-else>No site links added</span>
                </div>
                <b-table-simple responsive v-else>
                  <b-thead>
                    <b-tr>
                      <b-th>Title</b-th>
                      <b-th>URL</b-th>
                      <b-th v-if="editables.sitelinks.edit"></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(sitelink, sitelinkIndex) in editables.sitelinks.data.google_ads.settings.site_links"
                      v-bind:key="`${ sitelinkIndex }-${ sitelink.url }`">
                      <b-td>
                        <v-text-field v-if="editables.sitelinks.edit" v-model="sitelink.title" name="Site link Title"
                          autocapitalize="on" autocorrect="on" spellcheck="true" type="text" @blur="siteLinkCleaner"
                          :rules="$vrules.basicText('Site link title')" :required="true">
                        </v-text-field>
                        <!-- <b-input v-if="editables.sitelinks.edit" type="text" v-model="sitelink.title" /> -->
                        <div v-else>{{ sitelink.title }}</div>
                      </b-td>
                      <b-td>
                        <v-text-field v-if="editables.sitelinks.edit" v-model="sitelink.url" name="Site link URL"
                          autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                          :rules="$vrules.basicUri('Site link URL')" :required="true"></v-text-field>
                        <!-- <b-input v-if="editables.sitelinks.edit" type="text" v-model="sitelink.url" /> -->
                        <div v-else>{{ sitelink.url }}</div>
                      </b-td>
                      <b-td v-if="editables.sitelinks.edit" style="line-height: 100%; vertical-align: middle;">
                        <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                          @click="editables.sitelinks.data.google_ads.settings.site_links.splice(sitelinkIndex, 1)">
                          mdi-delete</v-icon>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </v-form>

            <v-form role="form" ref="form-sitelinks-new" v-model="editables.sitelinks.newvalid"
              @submit="sitelinkNewBlur" v-if="editables.sitelinks.edit" lazy-validation>
              <b-table-simple responsive>
                <b-thead>
                  <b-tr>
                    <b-th colspan="3"> Add a new site link</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>
                      <v-text-field v-model="editables.sitelinks.new.title" name="Site link Title" autocapitalize="on"
                        label="Site link Title" autocorrect="on" spellcheck="true" type="text"
                        :rules="$vrules.basicText('Site link title')" :required="true"></v-text-field>
                    </b-td>
                    <b-td>
                      <v-text-field v-model="editables.sitelinks.new.url" name="Site link URL" label="Site link URL"
                        autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                        :rules="$vrules.basicUri('Site link URL')" :required="true"></v-text-field>
                    </b-td>
                    <b-td style="line-height: 100%; vertical-align: middle;">
                      <v-icon v-if="editables.sitelinks.newvalid"
                        style="color: var(--success); text-align: left; cursor: pointer;" @click="sitelinkNewBlur()">
                        mdi-plus</v-icon>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </v-form>

          </template>
        </KTPortlet>
      </div>
      <div class="col-md-12 col-lg-6">
        <KTPortlet title="Competitors">
          <template v-slot:toolbar>
            <div v-if="editables.competitors.data === null"></div>
            <div v-else-if="editables.competitors.edit">
              <b-button @click="saveEdit('competitors')" pill variant="outline-success" style="margin-right: 20px;">SAVE
              </b-button>
              <b-button @click="cancelEdit('competitors')" pill variant="danger">CANCEL
              </b-button>
            </div>
            <b-button v-else @click="startEdit('competitors')" pill variant="info text-light">EDIT
            </b-button>
          </template>
          <template slot="body">
            <div class="row">
              <Loader :tableCount="3" v-if="editables.competitors.data === null" :inContent="true" :noText="true"
                :newStyle="1" :fields="['Title', 'URL']">
              </Loader>
              <div
                v-else-if="editables.competitors.data.google_ads.settings.competitors.length === 0 && !editables.competitors.edit"
                style="margin: 0 auto;">No competitors added</div>
              <div v-else>
                <v-chip class="ma-2" style="margin: 5px;"
                  v-for="(competitor, index) in editables.competitors.data.google_ads.settings.competitors"
                  v-bind:key="index">
                  {{ competitor }}
                  <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                    v-if="editables.competitors.edit"
                    @click="editables.competitors.data.google_ads.settings.competitors.splice(index, 1)">
                    mdi-delete</v-icon>
                </v-chip>
                <v-form role="form" ref="form-competitors-new" v-model="editables.competitors.newvalid"
                  v-if="editables.competitors.edit" @submit="competitorNewBlur" lazy-validation>
                  <v-text-field style="padding: 10px; width: calc(100% - 50px); display: inline-block"
                    v-model="editables.competitors.new" name="New competitor" autocapitalize="off"
                    label="New competitor" autocorrect="off" spellcheck="false" type="text"
                    :rules="$vrules.basicText('New competitor')" :required="true">
                  </v-text-field>
                  <v-icon v-if="editables.competitors.newvalid"
                    style="color: var(--success); text-align: left; cursor: pointer;" @click="competitorNewBlur()">
                    mdi-plus</v-icon>
                  <!-- <div class="form-group" style="padding: 10px;" v-if="editables.competitors.edit">
                    <label>Add a new <b>competitor</b></label>
                    <b-input type="text" v-model="editables.competitors.new" @blur.native="competitorNewBlur()" />
                  </div> -->
                </v-form>
              </div>
            </div>
          </template>
        </KTPortlet>
        <KTPortlet title="Negative Keywords">
          <template v-slot:toolbar>
            <div v-if="editables.negative_keywords.data === null"></div>
            <div v-else-if="editables.negative_keywords.edit">
              <b-button @click="saveEdit('negative_keywords')" pill variant="outline-success"
                style="margin-right: 20px;">SAVE
              </b-button>
              <b-button @click="cancelEdit('negative_keywords')" pill variant="danger">CANCEL
              </b-button>
            </div>
            <b-button v-else @click="startEdit('negative_keywords')" pill variant="info text-light">EDIT
            </b-button>
          </template>
          <template slot="body">
            <div class="row">
              <Loader :tableCount="3" v-if="editables.negative_keywords.data === null" :inContent="true" :noText="true"
                :newStyle="1" :fields="['Title', 'URL']">
              </Loader>
              <div
                v-else-if="editables.negative_keywords.data.google_ads.settings.negative_keywords.length === 0 && !editables.negative_keywords.edit"
                style="margin: 0 auto;">No Negative keywords added</div>
              <div v-else>
                <v-chip class="ma-2" style="margin: 5px;"
                  v-for="(negative_keyword, index) in editables.negative_keywords.data.google_ads.settings.negative_keywords"
                  v-bind:key="index">
                  {{ negative_keyword }}
                  <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                    v-if="editables.negative_keywords.edit"
                    @click="editables.negative_keywords.data.google_ads.settings.negative_keywords.splice(index, 1)">
                    mdi-delete</v-icon>
                </v-chip>
                <v-form role="form" ref="form-negative_keywords-new" v-model="editables.negative_keywords.newvalid"
                  v-if="editables.negative_keywords.edit" @submit="negative_keywordsNewBlur" lazy-validation>
                  <v-text-field style="padding: 10px; width: calc(100% - 50px); display: inline-block"
                    v-model="editables.negative_keywords.new" name="New negative keyword" autocapitalize="off"
                    label="New negative keyword" autocorrect="off" spellcheck="false" type="text"
                    :rules="$vrules.basicText('New negative keyword')" :required="true">
                  </v-text-field>
                  <v-icon v-if="editables.negative_keywords.newvalid"
                    style="color: var(--success); text-align: left; cursor: pointer;"
                    @click="negative_keywordsNewBlur()">
                    mdi-plus</v-icon>
                  <!-- <div class="form-group" style="padding: 10px;" v-if="editables.competitors.edit">
                    <label>Add a new <b>competitor</b></label>
                    <b-input type="text" v-model="editables.competitors.new" @blur.native="competitorNewBlur()" />
                  </div> -->
                </v-form>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
      <div class="col-md-12 col-lg-6">
        <KTPortlet title="Geo-Targeting">
          <template v-slot:toolbar>
            <div v-if="editables.geotarget.data === null"></div>
            <div v-else-if="editables.geotarget.edit">
              <b-button v-if="editables.geotarget.data.google_ads.settings.geotarget_ids.length > 0"
                @click="saveEdit('geotarget')" pill variant="outline-success" style="margin-right: 20px;">SAVE
              </b-button>
              <b-button @click="cancelEdit('geotarget')" pill variant="danger">CANCEL
              </b-button>
            </div>
            <b-button v-else @click="startEdit('geotarget')" pill variant="info text-light">EDIT
            </b-button>
          </template>
          <template slot="body">
            <v-form role="form" ref="form-geotarget" v-model="editables.geotarget.valid"
              @submit="e => saveEdit('geotarget', e)" lazy-validation>
              <v-text-field style="display: none;"></v-text-field>
              <div class="row">
                <Loader :tableCount="3" v-if="editables.geotarget.data === null" :inContent="true" :noText="true"
                  :newStyle="2" :fields="['Location', 'Type', 'Estimated Reach']">
                </Loader>
                <div v-else-if="editables.geotarget.data.google_ads.settings.geotarget_ids.length === 0"
                  style="margin: 0 auto;">
                  <template v-if="editables.geotarget.edit">
                    <h5 class="text-danger">You need a minimum of 1 geo-target</h5>
                    <v-text-field v-if="editables.geotarget.edit" style="display: none;"
                      :rules="$vrules.alwaysErrorMessage('You need at least one get-target')"></v-text-field>
                  </template>
                  <span v-else>No geo-targets added</span>
                </div>
                <b-table-simple responsive v-else>
                  <b-thead>
                    <b-tr>
                      <b-th>Location</b-th>
                      <b-th>Type</b-th>
                      <b-th>Estimated Reach
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon style="color: var(--info); text-align: left; cursor: pointer;" v-bind="attrs"
                              v-on="on">
                              mdi-help-circle</v-icon>
                          </template>
                          <div>
                            The more specific your targeting, the less estimated reach but higher conversion rate.
                          </div>
                        </v-tooltip>
                      </b-th>
                      <b-th v-if="editables.geotarget.edit"></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(geotarget, geotargetIndex) in geotarget_ids" v-bind:key="geotarget">
                      <b-th>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <div>
                              {{ getGeoTargetIntoById(geotarget).name }}
                              <v-icon small style="text-align: left; cursor: pointer; cursor: help;" v-bind="attrs"
                                v-on="on">
                                mdi-help-circle</v-icon>
                            </div>
                          </template>
                          <div>
                            {{ getGeoTargetIntoById(geotarget).text }}
                          </div>
                        </v-tooltip>
                      </b-th>
                      <b-td>
                        <div>{{ getGeoTargetIntoById(geotarget).type }}</div>
                      </b-td>
                      <b-td>
                        <div>{{ numberFormat(getGeoTargetIntoById(geotarget).reach) }}</div>
                      </b-td>
                      <b-td v-if="editables.geotarget.edit" style="line-height: 100%; vertical-align: middle;">
                        <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                          @click="editables.geotarget.data.google_ads.settings.geotarget_ids.splice(geotargetIndex, 1)">
                          mdi-delete</v-icon>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot
                    v-if="!editables.geotarget.edit && editables.geotarget.data.google_ads.settings.geotarget_ids.length > 1">
                    <b-tr
                      v-if="editables.geotarget.showFullList === false && geotarget_ids.length !== editables.geotarget.data.google_ads.settings.geotarget_ids.length">
                      <b-td colspan="3" style="text-align: center;"><button
                          @click="editables.geotarget.showFullList = true">click here to show more data (only showing
                          {{ geotarget_ids.length }}/{{
                              editables.geotarget.data.google_ads.settings.geotarget_ids.length
                          }})</button>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="2">Estimated reach:</b-td>
                      <b-td>
                        {{ numberFormat(getTotalEstimatedGeoReach()) }}
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </div>
            </v-form>

            <v-form role="form" ref="form-geotarget-new" v-model="editables.geotarget.newvalid"
              @submit="geotargetNewBlur" v-if="editables.geotarget.edit" lazy-validation>
              <v-text-field v-model="editables.geotarget.new" name="Search for a new location" autocapitalize="on"
                label="Search for a new location" autocorrect="off" spellcheck="false" type="text"
                @keyup.native="geotargetNewBlur()" :rules="$vrules.basicText('Search for a new location')"
                :required="true"></v-text-field>
              <div v-if="editables.geotarget.results === undefined"></div>
              <Loader v-else-if="editables.geotarget.results === null" :inContent="true" :noText="true" :newStyle="2"
                :tableCount="4" :fields="['Location', 'Type', 'Estimated Reach']">
              </Loader>
              <b-table-simple responsive v-else>
                <b-thead>
                  <b-th>Location</b-th>
                  <b-th>Type</b-th>
                  <b-th>Estimated Reach</b-th>
                  <b-th></b-th>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="geotarget in editables.geotarget.results" v-bind:key="geotarget.id">
                    <b-th>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            {{ getGeoTargetIntoById(geotarget.id).name }}
                            <v-icon small style="text-align: left; cursor: pointer; cursor: help;" v-bind="attrs"
                              v-on="on">
                              mdi-help-circle</v-icon>
                          </div>
                        </template>
                        <div>
                          {{ getGeoTargetIntoById(geotarget.id).text }}
                        </div>
                      </v-tooltip>
                    </b-th>
                    <b-td>
                      <div>{{ getGeoTargetIntoById(geotarget.id).type }}</div>
                    </b-td>
                    <b-td>
                      <div>{{ getGeoTargetIntoById(geotarget.id).reach }}</div>
                    </b-td>
                    <b-td v-if="editables.geotarget.edit" style="line-height: 100%; vertical-align: middle;">
                      <v-icon v-if="!newGeoTargetExists(geotarget)"
                        style="color: var(--success); text-align: left; cursor: pointer;"
                        @click="addNewGeoTarget(geotarget)">
                        mdi-plus</v-icon>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </v-form>
          </template>
        </KTPortlet>
      </div>
      <div class="col-md-12 col-lg-12">
        <KTPortlet
          :title="editables.ads.data !== null ? (activeAds > 10 ? 'Adverts (Too many active adverts - limit of 10)' : `Adverts (${ activeAds }/${ editables.ads.data.google_ads.settings.ads.length } active)`) : 'Adverts'">
          <template v-slot:toolbar>
            <div v-if="editables.ads.data === null"></div>
            <div v-else-if="editables.ads.edit">
              <b-button v-if="activeAds <= 10" @click="saveEdit('ads')" pill variant="outline-success"
                style="margin-right: 20px;">SAVE
              </b-button>
              <b-button @click="cancelEdit('ads')" pill variant="danger" style="margin-right: 20px;">CANCEL
              </b-button>
              <v-dialog v-model="editables.ads.importExportShow" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                  <b-button @click="importExportAds()" pill variant="info text-white">IMPORT/EXPORT
                  </b-button>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Import/Export ADs</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editables.ads.importExportRender" label="Config" required>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="importExportAds(false)">Close</v-btn>
                    <v-btn color="blue darken-1" text @click="importExportAds(true)">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <b-button v-else @click="startEdit('ads')" pill variant="info text-light">EDIT
            </b-button>
          </template>
          <template slot="body">
            <div>
              <v-form role="form" ref="form-ads" v-model="editables.ads.valid" @submit="e => saveEdit('ads', e)"
                lazy-validation>
                <Loader :tableCount="3" v-if="editables.ads.data === null" :inContent="true" :noText="true"
                  :newStyle="2" :fields="['', ' ']">
                </Loader>
                <div v-else-if="editables.ads.data.google_ads.settings.ads.length === 0 && !editables.ads.edit"
                  style="margin: 0 auto;">
                  No ADs</div>
                <template v-else-if="editables.ads.view === 1">
                  <div v-if="editables.ads.importExportShow"></div>
                  <div style="display: flex; flex-direction: row; flex-wrap: wrap;" v-else-if="!editables.ads.edit">
                    <div class="col-lg-6 col-md-12" v-for="(ad, adIndex) of editables.ads.data.google_ads.settings.ads"
                      v-bind:key="ad.id">
                      <div class="card ad" :state="(ad.active ? 'enabled' : 'disabled')"
                        :style="`text-align: left; padding: 0; background: none; ${ !editables.ads.edit ? 'border-radius: 4px;' : '' }'`">
                        <div style="position: absolute; right: 15px; top: -14px; padding: 5px;" class="status-chip">
                          <v-chip style="width: 90px; text-align: center;"
                            :color="`${ ad.active ? 'bg-success' : 'bg-danger' } text-white`" class="ma-2" x-small>AD
                            {{ ad.active ? 'Enabled' : 'Disabled' }}</v-chip>
                        </div>
                        <span style="font-size: 10px;">
                          <b style="font-weight: 700;">Ad · </b>{{
                              ad.url
                          }}</span>
                        <h5 style="margin: 0.5rem; margin-left: 0;" class="text-primary">
                          {{ ad.headline1 }}
                          <span v-if="ad.headline2 !== ''">| {{ ad.headline2 }}</span>
                          <span v-if="ad.headline3 !== ''">| {{ ad.headline3 }}</span>
                        </h5>
                        <div>
                          {{ ad.description1 }}
                        </div>
                        <div>
                          {{ ad.description2 }}
                        </div>
                        <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                          <span v-for="(siteL, index) in editables.ads.data.google_ads.settings.site_links"
                            v-bind:key="siteL.url" style="padding-right: 5px;">
                            <span v-if="index !== 0" style="padding-right: 5px;">
                              ·
                            </span>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" style="cursor: default;" class="text-primary">{{
                                    siteL.title
                                }}</span>
                              </template>
                              <span>Link points to: {{ siteL.url }}</span>
                            </v-tooltip>
                          </span>
                        </div>
                        <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-tabs content-class="mt-3" v-else v-model="editables.ads.viewTab" justified align="center">
                    <b-tab :title="ad.name" :active="adIndex === 0"
                      v-for="(ad, adIndex) of editables.ads.data.google_ads.settings.ads" v-bind:key="ad.id">
                      <template #title>
                        <v-icon :style="`color: var(--${ad.active ? 'success' : 'danger'});`" small>
                            mdi-brightness-1</v-icon> {{ ad.name }}
                      </template>
                      <div class="row" style="padding: 10px;">
                        <div class="col-lg-6 col-md-12">
                          <div class="card ad" :state="(ad.active ? 'enabled' : 'disabled')"
                            :style="`text-align: left; padding: 0; background: none; ${ !editables.ads.edit ? 'border-radius: 4px;' : '' }'`">
                            <div style="position: absolute; right: 15px; top: -14px; padding: 5px;" class="status-chip">
                              <v-chip style="width: 90px; text-align: center;"
                                :color="`${ ad.active ? 'bg-success' : 'bg-danger' } text-white`" class="ma-2" x-small>
                                AD
                                {{ ad.active ? 'Enabled' : 'Disabled' }}</v-chip>
                            </div>
                            <span style="font-size: 10px;">
                              <b style="font-weight: 700;">Ad · </b>{{
                                  ad.url
                              }}</span>
                            <h5 style="margin: 0.5rem; margin-left: 0;" class="text-primary">
                              {{ ad.headline1 }}
                              <span v-if="ad.headline2 !== ''">| {{ ad.headline2 }}</span>
                              <span v-if="ad.headline3 !== ''">| {{ ad.headline3 }}</span>
                            </h5>
                            <div>
                              {{ ad.description1 }}
                            </div>
                            <div>
                              {{ ad.description2 }}
                            </div>
                            <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                              <span v-for="(siteL, index) in editables.ads.data.google_ads.settings.site_links"
                                v-bind:key="siteL.url" style="padding-right: 5px;">
                                <span v-if="index !== 0" style="padding-right: 5px;">
                                  ·
                                </span>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style="cursor: default;" class="text-primary">{{
                                        siteL.title
                                    }}</span>
                                  </template>
                                  <span>Link points to: {{ siteL.url }}</span>
                                </v-tooltip>
                              </span>
                            </div>
                            <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                            </div>
                          </div>
                          <DemoAdTextr />
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="row">
                            <div class="col-lg-12">
                              <b-form-group label="AD Status">
                                <b-form-radio-group v-model="ad.active"
                                  :options="[{ text: 'Enabled', value: true }, { text: 'Disabled', value: false }]"
                                  @blur="adNewBlur()" button-variant="outline-primary" size="xs" buttons>
                                </b-form-radio-group>
                              </b-form-group>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6">
                              <v-text-field v-model="ad.name" name="AD Name" autocapitalize="on" label="AD Name"
                                autocorrect="on" spellcheck="true" type="text" :rules="$vrules.basicText('AD Name')"
                                :adId="adIndex" :required="true"></v-text-field>
                            </div>
                            <div class="col-lg-6">
                              <v-text-field v-model="ad.url" name="Site link URL" label="Site link URL"
                                autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                                :rules="$vrules.basicUri('Site link URL')" :adId="adIndex" :required="true">
                              </v-text-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <v-text-field v-model="ad.headline1" name="Headline 1" autocapitalize="on"
                                label="Headline 1" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                                :rules="$vrules.basicTextAds('Headline 1', 2, 30)" :required="true"></v-text-field>
                            </div>
                            <div class="col-lg-4">
                              <v-text-field v-model="ad.headline2" name="Headline 2" autocapitalize="on"
                                label="Headline 2" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                                :rules="$vrules.basicTextAds('Headline 2', 2, 30)" :required="false"></v-text-field>
                            </div>
                            <div class="col-lg-4">
                              <v-text-field v-model="ad.headline3" name="Headline 3" autocapitalize="on"
                                label="Headline 3" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                                :rules="$vrules.basicTextAds('Headline 3', 0, 30)" :required="false"></v-text-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <v-text-field v-model="ad.description1" name="Description 1" autocapitalize="on"
                                label="Description 1" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                                :rules="$vrules.basicTextAds('Description 1', 2, 90)" :required="true"></v-text-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <v-text-field v-model="ad.description2" name="Description 2" autocapitalize="on"
                                label="Description 2" autocorrect="on" spellcheck="true" type="text" :required="false"
                                :adId="adIndex" :rules="$vrules.basicTextAds('Description 2', 2, 90)">
                              </v-text-field>
                            </div>
                          </div>
                          <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                            <template v-if="ad.keywords.length === 0">
                              <h5 class="text-danger">You need a minimum of 1 keyword</h5>
                              <v-text-field style="display: none;"
                                :rules="$vrules.alwaysErrorMessage('You need at least one keyword')"></v-text-field>
                            </template>
                            <div>
                              <v-chip class="ma-2" style="margin: 5px;" v-for="(adKeyword, index) in ad.keywords"
                                v-bind:key="adKeyword">
                                {{ adKeyword }}
                                <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                                  @click="ad.keywords.splice(index, 1)" :adId="adIndex">
                                  mdi-delete</v-icon>
                              </v-chip>
                            </div>
                            <v-form role="form" :ref="`form-ads-item-keyword-${ adIndex }`"
                              v-if="editables.ads.forceReRender !== true" v-model="ad._validKeyword"
                              @submit="e => adItemKeyworkNewBlur(e, adIndex)" lazy-validation>
                              <v-text-field style="padding: 10px; width: calc(100% - 50px); display: inline-block"
                                v-model="ad._keywordModel" name="New keyword" autocapitalize="off" label="New keyword"
                                autocorrect="off" spellcheck="false" type="text"
                                :rules="$vrules.basicText('New keyword')" :required="true">
                              </v-text-field>
                              <v-icon xcv-if="ad._validKeyword"
                                style="color: var(--success); text-align: left; cursor: pointer;"
                                @click="adItemKeyworkNewBlur(undefined, adIndex)">
                                mdi-plus</v-icon>
                            </v-form>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="New AD" title-link-class="bg-primary text-white">
                      <div class="row" style="padding: 10px;">
                        <div class="col-lg-6 col-md-12">
                          <div class="card ad" :state="(editables.ads.new.active ? 'enabled' : 'disabled')"
                            :style="`text-align: left; padding: 0; background: none; border-radius: 4px`">
                            <div style="position: absolute; right: 15px; top: -14px; padding: 5px;" class="status-chip">
                              <v-chip style="width: 90px; text-align: center;"
                                :color="`${ editables.ads.new.active ? 'bg-success' : 'bg-danger' } text-white`"
                                class="ma-2" x-small>AD
                                {{ editables.ads.new.active ? 'Enabled' : 'Disabled' }}</v-chip>
                            </div>
                            <span style="font-size: 10px;">
                              <b style="font-weight: 700;">Ad · </b>{{
                                  editables.ads.new.url || 'https://ads.google.com'
                              }}</span>
                            <h5 style="margin: 0.5rem; margin-left: 0;" class="text-primary">
                              {{ editables.ads.new.headline1 || 'This is a headline' }}
                              <span v-if="editables.ads.new.headline2 !== ''">| {{ editables.ads.new.headline2
                              }}</span>
                              <span v-if="editables.ads.new.headline3 !== ''">| {{ editables.ads.new.headline3
                              }}</span>
                            </h5>
                            <div>
                              {{ editables.ads.new.description1 || 'I am a description' }}
                            </div>
                            <div>
                              {{ editables.ads.new.description2 || 'And I am another description' }}
                            </div>
                            <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                              <span v-for="(siteL, index) in editables.ads.data.google_ads.settings.site_links"
                                v-bind:key="siteL.url" style="padding-right: 5px;">
                                <span v-if="index !== 0" style="padding-right: 5px;">
                                  ·
                                </span>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style="cursor: default;" class="text-primary">{{
                                        siteL.title
                                    }}</span>
                                  </template>
                                  <span>Link points to: {{ siteL.url }}</span>
                                </v-tooltip>
                              </span>
                            </div>
                            <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                            </div>
                          </div>
                          <DemoAdTextr />
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <v-form role="form" ref="form-ads-new" xv-model="editables.ads.valid" @submit="adNewBlur"
                            lazy-validation>
                            <div class="row">
                              <div class="col-lg-12">
                                <b-form-group label="AD Status">
                                  <b-form-radio-group v-model="editables.ads.new.active"
                                    :options="[{ text: 'Enabled', value: true }, { text: 'Disabled', value: false }]"
                                    @blur="adNewBlur()" button-variant="outline-primary" size="xs" buttons>
                                  </b-form-radio-group>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <v-text-field v-model="editables.ads.new.name" name="AD Name" autocapitalize="on"
                                  label="AD Name" autocorrect="on" spellcheck="true" type="text"
                                  :rules="$vrules.basicText('AD Name')" :required="true" @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                              <div class="col-lg-6">
                                <v-text-field v-model="editables.ads.new.url" name="Site link URL" label="Site link URL"
                                  autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                                  :rules="$vrules.basicUri('Site link URL')" :required="true" @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <v-text-field v-model="editables.ads.new.headline1" name="Headline 1"
                                  autocapitalize="on" label="Headline 1" autocorrect="on" spellcheck="true" type="text"
                                  :rules="$vrules.basicTextAds('Headline 1', 2, 30)" :required="true"
                                  @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                              <div class="col-lg-4">
                                <v-text-field v-model="editables.ads.new.headline2" name="Headline 2"
                                  autocapitalize="on" label="Headline 2" autocorrect="on" spellcheck="true" type="text"
                                  :rules="$vrules.basicTextAds('Headline 2', 2, 30)" :required="false"
                                  @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                              <div class="col-lg-4">
                                <v-text-field v-model="editables.ads.new.headline3" name="Headline 3"
                                  autocapitalize="on" label="Headline 3" autocorrect="on" spellcheck="true" type="text"
                                  :rules="$vrules.basicTextAds('Headline 3', 0, 30)" :required="false"
                                  @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">
                                <v-text-field v-model="editables.ads.new.description1" name="Description 1"
                                  autocapitalize="on" label="Description 1" autocorrect="on" spellcheck="true"
                                  type="text" :rules="$vrules.basicTextAds('Description 1', 2, 90)" :required="true"
                                  @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">
                                <v-text-field v-model="editables.ads.new.description2" name="Description 2"
                                  autocapitalize="on" label="Description 2" autocorrect="on" spellcheck="true"
                                  type="text" :required="false" :rules="$vrules.basicTextAds('Description 2', 2, 90)"
                                  @blur="adNewBlur()">
                                </v-text-field>
                              </div>
                            </div>
                            <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                              <template v-if="editables.ads.new.keywords.length === 0">
                                <h5 class="text-danger">You need a minimum of 1 keyword</h5>
                                <v-text-field style="display: none;"
                                  :rules="$vrules.alwaysErrorMessage('You need at least one keyword')"></v-text-field>
                              </template>
                              <div>
                                <v-chip class="ma-2" style="margin: 5px;"
                                  v-for="(adKeyword, index) in editables.ads.new.keywords" v-bind:key="adKeyword">
                                  {{ adKeyword }}
                                  <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                                    @click="editables.ads.new.keywords.splice(index, 1)">
                                    mdi-delete</v-icon>
                                </v-chip>
                              </div>
                              <v-form role="form" :ref="`form-ads-item-keywords`"
                                v-if="editables.ads.forceReRender !== true" v-model="editables.ads.new._validKeyword"
                                @submit="e => adKeyworkNewBlur(e)" lazy-validation>
                                <v-text-field style="padding: 10px; width: calc(100% - 50px); display: inline-block"
                                  v-model="editables.ads.new._keywordModel" name="New keyword" autocapitalize="off"
                                  label="New keyword" autocorrect="off" spellcheck="false" type="text"
                                  :rules="$vrules.basicText('New keyword')" :required="true">
                                </v-text-field>
                                <v-icon xcv-if="editables.ads.new._validKeyword"
                                  style="color: var(--success); text-align: left; cursor: pointer;"
                                  @click="adKeyworkNewBlur()">
                                  mdi-plus</v-icon>
                              </v-form>
                            </div>
                            <b-button @click="adNewBlur()">
                              <v-icon style="color: var(--success); text-align: left;">
                                mdi-plus</v-icon> SAVE AD
                            </b-button>
                          </v-form>
                        </div>
                      </div>

                    </b-tab>
                  </b-tabs>
                </template>
                <template v-else-if="editables.ads.view === 0">
                  <div style="display: flex; flex-direction: row; flex-wrap: wrap;"
                    v-if="!editables.ads.importExportShow">
                    <div class="col-lg-6 col-md-12" v-for="(ad, adIndex) of editables.ads.data.google_ads.settings.ads"
                      v-bind:key="ad.id">
                      <div class="card ad"
                        :state="editables.ads.edit ? undefined : (ad.active ? 'enabled' : 'disabled')"
                        :style="`text-align: left; padding: 0; background: none; ${ !editables.ads.edit ? 'border-radius: 4px;' : '' }'`">
                        <div v-if="!editables.ads.edit"
                          style="position: absolute; right: 15px; top: -14px; padding: 5px;" class="status-chip">
                          <v-chip style="width: 90px; text-align: center;"
                            :color="`${ ad.active ? 'bg-success' : 'bg-danger' } text-white`" class="ma-2" x-small>AD
                            {{ ad.active ? 'Enabled' : 'Disabled' }}</v-chip>
                        </div>
                        <div v-else
                          style="position: absolute; right: -3px; top: -9px; background: var(--v-secondary-base); border-radius: 50%; padding: 5px;">
                          <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                            @click="editables.ads.data.google_ads.settings.ads.splice(adIndex, 1)">
                            mdi-delete</v-icon>
                        </div>
                        <div class="row" v-if="editables.ads.edit">
                          <div class="col-lg-1">
                            <v-checkbox v-model="ad.active"></v-checkbox>
                          </div>
                          <div class="col-lg-5">
                            <v-text-field v-model="ad.name" name="AD Name" autocapitalize="on" label="AD Name"
                              autocorrect="on" spellcheck="true" type="text" :rules="$vrules.basicText('AD Name')"
                              :adId="adIndex" :required="true"></v-text-field>
                          </div>
                          <div class="col-lg-6">
                            <v-text-field v-model="ad.url" name="Site link URL" label="Site link URL"
                              autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                              :rules="$vrules.basicUri('Site link URL')" :adId="adIndex" :required="true">
                            </v-text-field>
                          </div>
                        </div>
                        <span v-if="!editables.ads.edit" style="font-size: 10px;">
                          <b style="font-weight: 700;">Ad · </b>{{
                              ad.url
                          }}</span>
                        <div class="row" v-if="editables.ads.edit">
                          <div class="col-lg-4">
                            <v-text-field v-model="ad.headline1" name="Headline 1" autocapitalize="on"
                              label="Headline 1" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                              :rules="$vrules.basicTextAds('Headline 1', 2, 30)" :required="true"></v-text-field>
                          </div>
                          <div class="col-lg-4">
                            <v-text-field v-model="ad.headline2" name="Headline 2" autocapitalize="on"
                              label="Headline 2" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                              :rules="$vrules.basicTextAds('Headline 2', 2, 30)" :required="false"></v-text-field>
                          </div>
                          <div class="col-lg-4">
                            <v-text-field v-model="ad.headline3" name="Headline 3" autocapitalize="on"
                              label="Headline 3" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                              :rules="$vrules.basicTextAds('Headline 3', 0, 30)" :required="false"></v-text-field>
                          </div>
                        </div>
                        <h5 style="margin: 0.5rem; margin-left: 0;" v-else class="text-primary">
                          {{ ad.headline1 }}
                          <span v-if="ad.headline2 !== ''">| {{ ad.headline2 }}</span>
                          <span v-if="ad.headline3 !== ''">| {{ ad.headline3 }}</span>
                        </h5>
                        <div class="row" v-if="editables.ads.edit">
                          <div class="col-lg-12">
                            <v-text-field v-model="ad.description1" name="Description 1" autocapitalize="on"
                              label="Description 1" autocorrect="on" spellcheck="true" type="text" :adId="adIndex"
                              :rules="$vrules.basicTextAds('Description 1', 2, 90)" :required="true"></v-text-field>
                          </div>
                        </div>
                        <div class="row" v-if="editables.ads.edit">
                          <div class="col-lg-12">
                            <v-text-field v-model="ad.description2" name="Description 2" autocapitalize="on"
                              label="Description 2" autocorrect="on" spellcheck="true" type="text" :required="false"
                              :adId="adIndex" :rules="$vrules.basicTextAds('Description 2', 2, 90)">
                            </v-text-field>
                          </div>
                        </div>
                        <div v-if="!editables.ads.edit">
                          {{ ad.description1 }}
                        </div>
                        <div v-if="!editables.ads.edit">
                          {{ ad.description2 }}
                        </div>
                        <div style="max-width : 100%; font-size: 10px;margin-top:5px;" v-if="!editables.ads.edit">
                          <span v-for="(siteL, index) in editables.ads.data.google_ads.settings.site_links"
                            v-bind:key="siteL.url" style="padding-right: 5px;">
                            <span v-if="index !== 0" style="padding-right: 5px;">
                              ·
                            </span>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" style="cursor: default;" class="text-primary">{{
                                    siteL.title
                                }}</span>
                              </template>
                              <span>Link points to: {{ siteL.url }}</span>
                            </v-tooltip>
                          </span>
                        </div>
                        <div style="max-width : 100%; font-size: 10px;margin-top:5px;" v-if="!editables.ads.edit">
                          <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" style="cursor: help;">
                              <span v-for="(adKeyword, index) in ad.keywords" v-bind:key="adKeyword"
                                style="padding-right: 5px;">
                                <span v-if="index !== 0" style="padding-right: 5px;">
                                  ·
                                </span>
                                <span>{{ adKeyword }}</span>
                              </span>
                            </span>
                          </template>
                          <span>These are the keywords for your ad</span>
                        </v-tooltip> -->
                        </div>
                        <div style="max-width : 100%; font-size: 10px;margin-top:5px;" v-else>
                          <template v-if="ad.keywords.length === 0">
                            <h5 class="text-danger">You need a minimum of 1 keyword</h5>
                            <v-text-field style="display: none;"
                              :rules="$vrules.alwaysErrorMessage('You need at least one keyword')"></v-text-field>
                          </template>
                          <div>
                            <v-chip class="ma-2" style="margin: 5px;" v-for="(adKeyword, index) in ad.keywords"
                              v-bind:key="adKeyword">
                              {{ adKeyword }}
                              <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                                @click="ad.keywords.splice(index, 1)" :adId="adIndex">
                                mdi-delete</v-icon>
                            </v-chip>
                          </div>
                          <v-form role="form" :ref="`form-ads-item-keyword-${ adIndex }`"
                            v-if="editables.ads.forceReRender !== true" v-model="ad._validKeyword"
                            @submit="e => adItemKeyworkNewBlur(e, adIndex)" lazy-validation>
                            <v-text-field style="padding: 10px; width: calc(100% - 50px); display: inline-block"
                              v-model="ad._keywordModel" name="New keyword" autocapitalize="off" label="New keyword"
                              autocorrect="off" spellcheck="false" type="text" :rules="$vrules.basicText('New keyword')"
                              :required="true">
                            </v-text-field>
                            <v-icon xcv-if="ad._validKeyword"
                              style="color: var(--success); text-align: left; cursor: pointer;"
                              @click="adItemKeyworkNewBlur(undefined, adIndex)">
                              mdi-plus</v-icon>
                          </v-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </v-form>
            </div>
            <v-form v-if="!editables.ads.importExportShow && editables.ads.edit && editables.ads.view === 0" role="form"
              ref="form-ads-new" xv-model="editables.ads.valid" @submit="adNewBlur" lazy-validation>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div class="row col-lg-6 col-md-12">
                  <div class="card ad" style="text-align: left; padding: 0; background: none;">
                    <div
                      style="position: absolute; right: -3px; top: -9px; background: var(--v-secondary-base); border-radius: 15px; padding: 5px; cursor: pointer;"
                      @click="adNewBlur()">
                      <v-icon style="color: var(--success); text-align: left;">
                        mdi-plus</v-icon> ADD NEW AD
                    </div>
                    <div class="row">
                      <div class="col-lg-1">
                        <v-checkbox v-model="editables.ads.new.active" @blur="adNewBlur()"></v-checkbox>
                      </div>
                      <div class="col-lg-5">
                        <v-text-field v-model="editables.ads.new.name" name="AD Name" autocapitalize="on"
                          label="AD Name" autocorrect="on" spellcheck="true" type="text"
                          :rules="$vrules.basicText('AD Name')" :required="true" @blur="adNewBlur()"></v-text-field>
                      </div>
                      <div class="col-lg-6">
                        <v-text-field v-model="editables.ads.new.url" name="Site link URL" label="Site link URL"
                          autocapitalize="off" autocorrect="off" spellcheck="false" type="text"
                          :rules="$vrules.basicUri('Site link URL')" :required="true" @blur="adNewBlur()">
                        </v-text-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <v-text-field v-model="editables.ads.new.headline1" name="Headline 1" autocapitalize="on"
                          label="Headline 1" autocorrect="on" spellcheck="true" type="text"
                          :rules="$vrules.basicTextAds('Headline 1', 2, 30)" :required="true" @blur="adNewBlur()">
                        </v-text-field>
                      </div>
                      <div class="col-lg-4">
                        <v-text-field v-model="editables.ads.new.headline2" name="Headline 2" autocapitalize="on"
                          label="Headline 2" autocorrect="on" spellcheck="true" type="text"
                          :rules="$vrules.basicTextAds('Headline 2', 2, 30)" :required="false" @blur="adNewBlur()">
                        </v-text-field>
                      </div>
                      <div class="col-lg-4">
                        <v-text-field v-model="editables.ads.new.headline3" name="Headline 3" autocapitalize="on"
                          label="Headline 3" autocorrect="on" spellcheck="true" type="text"
                          :rules="$vrules.basicTextAds('Headline 3', 0, 30)" :required="false" @blur="adNewBlur()">
                        </v-text-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <v-text-field v-model="editables.ads.new.description1" name="Description 1" autocapitalize="on"
                          label="Description 1" autocorrect="on" spellcheck="true" type="text"
                          :rules="$vrules.basicTextAds('Description 1', 2, 90)" :required="true" @blur="adNewBlur()">
                        </v-text-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <v-text-field v-model="editables.ads.new.description2" name="Description 2" autocapitalize="on"
                          label="Description 2" autocorrect="on" spellcheck="true" type="text" :required="false"
                          :rules="$vrules.basicTextAds('Description 2', 2, 90)" @blur="adNewBlur()">
                        </v-text-field>
                      </div>
                    </div>
                    <div style="max-width : 100%; font-size: 10px;margin-top:5px;">
                      <template v-if="editables.ads.new.keywords.length === 0">
                        <h5 class="text-danger">You need a minimum of 1 keyword</h5>
                        <v-text-field style="display: none;"
                          :rules="$vrules.alwaysErrorMessage('You need at least one keyword')"></v-text-field>
                      </template>
                      <div>
                        <v-chip class="ma-2" style="margin: 5px;"
                          v-for="(adKeyword, index) in editables.ads.new.keywords" v-bind:key="adKeyword">
                          {{ adKeyword }}
                          <v-icon style="color: var(--danger); text-align: left; cursor: pointer;"
                            @click="editables.ads.new.keywords.splice(index, 1)">
                            mdi-delete</v-icon>
                        </v-chip>
                      </div>
                      <v-form role="form" :ref="`form-ads-item-keywords`" v-if="editables.ads.forceReRender !== true"
                        v-model="editables.ads.new._validKeyword" @submit="e => adKeyworkNewBlur(e)" lazy-validation>
                        <v-text-field style="padding: 10px; width: calc(100% - 50px); display: inline-block"
                          v-model="editables.ads.new._keywordModel" name="New keyword" autocapitalize="off"
                          label="New keyword" autocorrect="off" spellcheck="false" type="text"
                          :rules="$vrules.basicText('New keyword')" :required="true">
                        </v-text-field>
                        <v-icon xcv-if="editables.ads.new._validKeyword"
                          style="color: var(--success); text-align: left; cursor: pointer;" @click="adKeyworkNewBlur()">
                          mdi-plus</v-icon>
                      </v-form>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cCodes from 'country-calling-code';
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Swal from "sweetalert2";
import TLib from "./lib";
import DemoAdTextr from './demoAdTextr.vue';
//import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader,
    DemoAdTextr
  },
  watch: {},
  data() {
    return {
      editables: {
        company: {
          valid: false,
          newvalid: false,
          edit: false,
          save: null,
          data: null
        },
        sitelinks: {
          valid: false,
          newvalid: false,
          edit: false,
          new: {
            title: '',
            url: ''
          },
          save: null,
          data: null
        },
        ads: {
          view: 1,
          viewTab: 0,
          importExportShow: false,
          importExportRender: '',
          forceReRender: false,
          valid: false,
          newvalid: false,
          edit: false,
          new: {
            active: false,
            name: '',
            url: '',
            headline1: '',
            headline2: '',
            headline3: '',
            description1: '',
            description2: '',
            keywords: [],
            _validKeyword: false,
            _keywordModel: ''
          },
          save: null,
          data: null
        },
        competitors: {
          valid: false,
          newvalid: false,
          edit: false,
          new: '',
          save: null,
          data: null
        },
        negative_keywords: {
          valid: false,
          newvalid: false,
          edit: false,
          new: '',
          save: null,
          data: null
        },
        geotarget: {
          showFullList: false,
          valid: false,
          newvalid: false,
          edit: false,
          new: '',
          results: undefined,
          timeout: null,
          searchkey: 0,
          save: null,
          data: null
        }
      },
      refCustomer: null,
      editClient: false,
      suggestion: null
    };
  },
  methods: {
    importExportAds(closeOrSave) {
      if (closeOrSave !== undefined) {
        if (closeOrSave === false) {
          this.editables.ads.importExportShow = false;
          this.editables.ads.importExportRender = "";
          return;
        }
        try {
          this.editables.ads.data.google_ads.settings.ads = JSON.parse(atob(this.editables.ads.importExportRender));
          this.editables.ads.importExportShow = false;
          this.editables.ads.importExportRender = "";
        } catch (exc) {
          alert('Error: Invalid config');
        }
        return;
      }

      // editables.ads.data.google_ads.settings.ads
      this.editables.ads.importExportRender = btoa(JSON.stringify(this.editables.ads.data.google_ads.settings.ads));
      this.editables.ads.importExportShow = true;
    },
    numberFormat(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    newGeoTargetExists(geotarget) {
      return this.editables.geotarget.data.google_ads.settings.geotarget_ids.indexOf(geotarget.id) >= 0;
    },
    addNewGeoTarget(geotarget) {
      if (this.newGeoTargetExists(geotarget)) {
        return;
      }
      this.editables.geotarget.data.google_ads.settings.geotargets[geotarget.id] = geotarget;
      this.editables.geotarget.data.google_ads.settings.geotarget_ids.push(geotarget.id);
    },
    geoObjToArr(objToArr) {
      let outArr = [];
      for (let key of Object.keys(objToArr)) {
        outArr.push(objToArr[key]);
      }
      return outArr;
    },
    getTotalEstimatedGeoReach(lvl = 1) {
      let reach = 0;
      if (lvl === 1 || lvl === 3) {
        for (let item of this.geoObjToArr(this.editables.geotarget.data.google_ads.settings.geotargets)) {
          reach += Number.parseInt(item.reach);
        }
      }
      if ((lvl === 2 || lvl === 3) && this.editables.geotarget.results !== undefined && this.editables.geotarget.results !== null)
        for (let item of this.editables.geotarget.results) {
          reach += item.reach;
        }
      return reach;
    },
    getGeoTargetIntoById(id) {
      for (let item of this.geoObjToArr(this.editables.geotarget.data.google_ads.settings.geotargets)) {
        if (item.id === id) return item;
      }
      if (this.editables.geotarget.results !== undefined && this.editables.geotarget.results !== null)
        for (let item of this.editables.geotarget.results) {
          if (item.id === id) return item;
        }
      return {};
    },
    dogeotargetNewBlur(skey) {
      const self = this;
      if (self.editables.geotarget.searchkey != skey) return;
      TLib.searchGeoLocation(self, self.$route.params.serviceId, self.editables.geotarget.data.address.country, self.editables.geotarget.new).then(x => {
        if (self.editables.geotarget.searchkey != skey) return;
        //self.editables.sitelinks.new = '';
        self.editables.geotarget.results = x;
      }).catch(self.$log.error);
    },
    geotargetNewBlur(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      this.editables.geotarget.results = null;
      if (this.editables.geotarget.new.length > 2) {
        //this.editables.sitelinks.data.site_links.push(JSON.parse(JSON.stringify(this.editables.sitelinks.new)));
        //this.editables.sitelinks.new.url = '';
        let timeNow = new Date().getTime();
        this.editables.geotarget.searchkey = `${ timeNow }`;
        const self = this;
        clearTimeout(this.editables.geotarget.timeout);
        this.editables.timeout = setTimeout(() => self.dogeotargetNewBlur(`${ timeNow }`), 250);
      }
    },
    sitelinkNewBlur(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs[`form-sitelinks-new`].validate())
        return;
      this.editables.sitelinks.data.google_ads.settings.site_links.push(JSON.parse(JSON.stringify(this.editables.sitelinks.new)));
      this.editables.sitelinks.new.title = '';
      this.editables.sitelinks.new.url = '';
      this.siteLinkCleaner();
    },
    siteLinkCleaner(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();

      //console.log('clean sitelinks');
      for (let iSL = 0; iSL < this.editables.sitelinks.data.google_ads.settings.site_links.length; iSL++) {
        let titleSPlit = this.editables.sitelinks.data.google_ads.settings.site_links[iSL].title.split(' ');
        //console.log(titleSPlit);
        for (let i = 0; i < titleSPlit.length; i++) {
          titleSPlit[i] = titleSPlit[i][0].toUpperCase() + titleSPlit[i].substr(1).toLowerCase();
        }
        this.editables.sitelinks.data.google_ads.settings.site_links[iSL].title = titleSPlit.join(' ');
      }
    },
    adNewBlur(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs[`form-ads-new`].validate()) {
        return;
      }
      this.editables.ads.data.google_ads.settings.ads.push(JSON.parse(JSON.stringify(this.editables.ads.new)));
      this.editables.ads.new.active = false;
      this.editables.ads.new.name = '';
      this.editables.ads.new.url = '';
      this.editables.ads.new.headline1 = '';
      this.editables.ads.new.headline2 = '';
      this.editables.ads.new.headline3 = '';
      this.editables.ads.new.description1 = '';
      this.editables.ads.new.description2 = '';
      this.editables.ads.new.keywords = [];
      this.editables.ads.new._validKeyword = false;
      this.editables.ads.new._keywordModel = '';

      const self = this;
      setTimeout(() => {
        self.editables.ads.viewTab = self.editables.ads.data.google_ads.settings.ads.length - 1;
      }, 100);
    },
    adItemKeyworkNewBlur(e, keyIndex) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      const refKey = `form-ads-item-keyword-${ keyIndex }`;
      if (!this.$refs[refKey][0].validate())
        return;
      let vAsStr = `${ this.editables.ads.data.google_ads.settings.ads[keyIndex]._keywordModel }`;
      let vASclean = vAsStr.toLowerCase().trim();
      for (let ad of this.editables.ads.data.google_ads.settings.ads) {
        for (let keyword of ad.keywords) {
          if (keyword.toLowerCase().trim() == vASclean) {
            alert(`${ vAsStr } is already in AD ${ ad.name }`);
            return;
          }
        }
      }
      this.editables.ads.data.google_ads.settings.ads[keyIndex].keywords.push(vAsStr);
      this.editables.ads.data.google_ads.settings.ads[keyIndex]._validKeyword = false;
      this.editables.ads.data.google_ads.settings.ads[keyIndex]._keywordModel = '';
      this.editables.ads.forceReRender = true;
      const self = this;
      self.$nextTick(() => {
        self.editables.ads.forceReRender = false;
      });
    },
    adKeyworkNewBlur(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs['form-ads-item-keywords'].validate())
        return;

      let vAsStr = `${ this.editables.ads.new._keywordModel }`;
      let vASclean = vAsStr.toLowerCase().trim();
      for (let ad of this.editables.ads.data.google_ads.settings.ads) {
        for (let keyword of ad.keywords) {
          if (keyword.toLowerCase().trim() == vASclean) {
            alert(`${ vAsStr } is already in AD ${ ad.name }`);
            return;
          }
        }
      }
      for (let keyword of this.editables.ads.new.keywords) {
        if (keyword.toLowerCase().trim() == vASclean) {
          alert(`${ vAsStr } is already in keywords`);
          return;
        }
      }
      this.editables.ads.new.keywords.push(vAsStr);
      this.editables.ads.new._validKeyword = false;
      this.editables.ads.new._keywordModel = '';
      this.editables.ads.forceReRender = true;
      const self = this;
      self.$nextTick(() => {
        self.editables.ads.forceReRender = false;
        self.adNewBlur();
      });
    },
    competitorNewBlur(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs[`form-competitors-new`].validate())
        return;
      this.editables.competitors.data.google_ads.settings.competitors.push(JSON.parse(JSON.stringify(this.editables.competitors.new)));
      this.editables.competitors.new = '';
    },
    negative_keywordsNewBlur(e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$refs[`form-negative_keywords-new`].validate())
        return;
      this.editables.negative_keywords.data.google_ads.settings.negative_keywords.push(JSON.parse(JSON.stringify(this.editables.negative_keywords.new)));
      this.editables.negative_keywords.new = '';
    },
    baseStartEdit(ofo) {
      switch (ofo) {
        case "ads": {
          for (let xad of this.editables[ofo].data.google_ads.settings.ads) {
            xad['_validKeyword'] = false;
            xad['_keywordModel'] = '';
          }
          break;
        }
        case "geotarget": {
          this.editables.geotarget.showFullList = true;
          break;
        }
      }
    },
    startEdit(ofo) {
      this.editables[ofo].edit = true;
      this.editables[ofo].forceReRender = false;
      const self = this;
      self.baseStartEdit(ofo);
      self.$nextTick(() => {
        if (!this.$tools.isNullOrUndefined(this.$refs[`form-${ ofo }`])) {
          if (!this.$refs[`form-${ ofo }`].validate())
            return;
        }
      });
    },
    saveEdit(ofo, e) {
      if (!this.$tools.isNullOrUndefined(e)) e.preventDefault();
      if (!this.$tools.isNullOrUndefined(this.$refs[`form-${ ofo }`])) {
        if (!this.$refs[`form-${ ofo }`].validate()) {
          if (ofo === 'ads') {
            let errors = [];
            for (let err of this.$refs[`form-${ ofo }`].inputs) {
              if (err.hasError) {
                errors.push({ name: err.$attrs.name, adId: Number.parseInt(`${ err.$attrs.adId }`), error: err.errorBucket });
              }
            }
            //console.log(errors);
            let errAds = {};
            for (let err of errors) {
              errAds[err.adId] = errAds[err.adId] || [];
              errAds[err.adId].push(err);
            }
            //console.log(errAds);
            let messages = [];
            for (let err of Object.keys(errAds)) {
              messages.push(`<h4>AD #${ errAds[err][0].adId + 1 }: ${ this.editables.ads.data.google_ads.settings.ads[err].name }</h4>`);
              for (let errObj of errAds[err]) {
                if (errObj.error[0].indexOf(errObj.name) >= 0)
                  messages.push(`${ errObj.error[0] }<br />`);
                else
                  messages.push(`${ errObj.name }: ${ errObj.error[0] }<br />`);
              }
            }
            //console.log(messages);
            Swal.fire({
              //timer: 60000,
              title: "Please fix the following fields first:",
              html: messages.join('<br />'),
              //icon: "danger",
              confirmButtonClass: "btn btn-secondary"
            });

          }
          return;
        }
      }

      this.editables[ofo].save = JSON.parse(JSON.stringify(this.editables[ofo].data));
      this.editables[ofo].data = null;
      this.editables[ofo].edit = false;
      let dataToSend = null;
      switch (ofo) {
        case "company": {
          dataToSend = {
            name: this.editables[ofo].save.name,
            display_name: this.editables[ofo].save.google_ads.settings.display_name,
            address: this.editables[ofo].save.address,
            url: this.editables[ofo].save.url,
            call_extension: {
              country: this.editables[ofo].save.address.country,
              number: this.editables[ofo].save.google_ads.settings.call_extension.number,
            },
            geotargeting_accuracy: this.editables[ofo].save.google_ads.settings.geotargeting_accuracy,
            targeting_accuracy: this.editables[ofo].save.google_ads.settings.targeting_accuracy,
          };
          let conCode = dataToSend.call_extension.country.toUpperCase();
          let foundCCode = '--';
          for (let count of cCodes) {
            if (conCode === count.isoCode2) {
              foundCCode = `+${ count.countryCodes }`;
              break;
            }
          }
          dataToSend.call_extension.number = dataToSend.call_extension.number.replace(foundCCode, '0');
          if (conCode === 'US') {
            dataToSend.call_extension.number[0] = '1';
          }
          break;
        }
        case "competitors": {
          dataToSend = {
            competitors: this.editables[ofo].save.google_ads.settings.competitors
          };
          break;
        }
        case "negative_keywords": {
          dataToSend = {
            negative_keywords: this.editables[ofo].save.google_ads.settings.negative_keywords
          };
          break;
        }
        case "geotarget": {
          dataToSend = {
            geotarget_ids: this.editables[ofo].save.google_ads.settings.geotarget_ids
          };
          break;
        }
        case "sitelinks": {
          //this.siteLinkCleaner();
          dataToSend = {
            site_links: this.editables[ofo].save.google_ads.settings.site_links
          };
          break;
        }
        case "ads": {
          for (let xad of this.editables[ofo].save.google_ads.settings.ads) {
            delete xad['_validKeyword'];
            delete xad['_keywordModel'];
          }
          dataToSend = {
            ads: this.editables[ofo].save.google_ads.settings.ads
          };
          break;
        }
      }

      if (this.$tools.isNullOrUndefined(dataToSend)) {
        return;
      }

      const self = this;
      TLib.setAdBudClient(this, this.$route.params.serviceId, dataToSend).then(() => {
        self.editables[ofo].save = null;
        self.updateService(this.$route.params.serviceId);
      }).catch(xc => {
        self.$log.error(xc);
        self.editables[ofo].data = self.editables[ofo].save;
        self.baseStartEdit(ofo);
        self.editables[ofo].edit = true;
      });
    },
    cancelEdit(ofo) {
      this.editables[ofo].data = JSON.parse(JSON.stringify(this.refCustomer));
      this.editables[ofo].edit = false;
      this.editables[ofo].valid = false;
      this.editables[ofo].results = undefined;
    },
    updateService(serviceId, forced = false, noCache = false) {
      let self = this;
      self.$data.customer = null;
      self.$data.refCustomer = null;
      if (forced) {
        for (let keyo of Object.keys(self.editables)) {
          self.editables[keyo].edit = false;
          self.editables[keyo].data = null;
          self.editables[keyo].valid = false;
          self.editables[keyo].showFullList = false;
        }
      }
      /*self.$data.suggestion = null;
      TLib.getAdBudSuggestions(self, serviceId, forced || noCache).then(sugg => {
        if (sugg.description === undefined) return;
        if (sugg.completed === true) return;
        self.$data.suggestion = sugg;
      }).catch(self.$log.error);*/
      TLib.getAdBudClient(self, serviceId, forced || noCache)
        .then(async z => {
          let refCustomer = JSON.parse(JSON.stringify(z));
          refCustomer.google_ads.settings.ads = (await TLib.getAdBudADsList(self, serviceId, forced || noCache))[0].ads;
          let conCode = refCustomer.google_ads.settings.call_extension.country.toUpperCase();
          let foundCCode = '--';
          for (let count of cCodes) {
            if (conCode === count.isoCode2) {
              foundCCode = `+${ count.countryCodes }`;
              break;
            }
          }
          if (refCustomer.google_ads.settings.call_extension.number !== null)
            refCustomer.google_ads.settings.call_extension.number = foundCCode + refCustomer.google_ads.settings.call_extension.number.substring(1);

          self.$data.refCustomer = refCustomer;
          for (let keyo of Object.keys(self.editables)) {
            if (self.editables[keyo].edit === false || forced)
              self.cancelEdit(keyo);
          }
        })
        .catch(self.$log.error);
    }
    /*updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }*/
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    geotarget_ids() {
      if (this.editables.geotarget.showFullList)
        return this.editables.geotarget.data.google_ads.settings.geotarget_ids;

      return this.editables.geotarget.data.google_ads.settings.geotarget_ids.slice(0, 5);
    },
    activeAds() {
      return this.editables.ads.data.google_ads.settings.ads.filter(x => x.active).length;
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off("ws-advertising-customer-updated");
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Advertising" },
      { title: "Adverts" }
    ]);

    this.updateService(this.$route.params.serviceId, true);

    let self = this;

    self.$eventBus.on("ws-advertising-customer-updated", x => {
      if (self.$route.params.serviceId !== `${ x }`) return;
      self.updateService(self.$route.params.serviceId, false, true);
    });
    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x, true, true));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>

<style>
.card.ad[state="disabled"]::after {
  content: "";
  background: rgba(242, 242, 242, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: -13px;
  margin-left: -13px;
}

.card.ad .status-chip {
  z-index: 2;
}
</style>